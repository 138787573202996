<template>
  <HeroBanner
    :title="content.config?.title?.value"
    :button-text="content.config?.buttonText?.value"
    :button-text-link="content.config?.buttonTextLink?.value"
    :is-compact="content.config?.isCompact?.value"
    :text-horizontal-alignment="content.config?.textHorizontalAlignment?.value"
    :media="computedMedia"
    :text-content="textContentWithProperInternalLinks"
    :should-display-logo="content?.config?.shouldDisplayLogo?.value"
    :logo-color="content?.config?.logoColor?.value"
  />
</template>

<script setup lang="ts">
import HeroBanner from "@/components/molecules/HeroBanner";
import { type CmsElement } from "@/types/cms";
import { type Media } from "@shopware-pwa/types";
import { type HorizontalAlignment } from "@/types";
import { mediaUpdater } from "@/utils/mediaUpdater";
import { useInternalLinkMapper } from "@/composables/useInternalLinkMapper";

const { mapHTMLWithLinks } = useInternalLinkMapper();

const props = defineProps<{
  content: CmsElement<{
    title: string;
    buttonText: string;
    buttonTextLink: string;
    isCompact: boolean;
    textHorizontalAlignment: HorizontalAlignment;
    media: Media;
    textContent: string;
    shouldDisplayLogo?: boolean;
    logoColor?: string;
    backgroundMediaType?: string;
    mediaHevc?: Media;
    mediaAv1?: Media;
    mediaWebm?: Media;
    mediaH264?: Media;
  }>;
}>();
const updatedMedia = props.content.data as Media;

const computedMedia = computed(() => {
  const bgType = props.content.config?.backgroundMediaType?.value;
  if (bgType === "video") {
    return {
      hevc: props.content.config?.mediaHevc?.value,
      av1: props.content.config?.mediaAv1?.value,
      webm: props.content.config?.mediaWebm?.value,
      h264: props.content.config?.mediaH264?.value,
    };
  }
  return props.content.config?.media.value;
});

const textContentWithProperInternalLinks = await mapHTMLWithLinks(
  props.content?.config?.textContent?.value,
);
</script>
